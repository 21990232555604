import { useEffect, useRef, useState } from 'react';
import type { Range } from 'react-date-range';
import { DateRange } from 'react-date-range';

import moment from 'moment';

import { Event } from '@mui/icons-material';

import styles from './date-range-input.module.css';

export const DateRangeInput = ({
  startDate: initialStartDate,
  endDate: initialEndDate,
  trigger,
  align,
  forceActive,
  updateForceActive,
  onChange,
}: {
  startDate: string | null;
  endDate: string | null;
  align?: 'left' | 'right' | 'center';
  trigger?: JSX.Element;
  forceActive?: boolean;
  updateForceActive?: (val: boolean) => void;
  onChange: (startDate: string, endDate: string) => void;
}) => {
  const [isActive, setIsActive] = useState(false);
  const [state, setState] = useState<Range>({
    startDate: initialStartDate ? moment(initialStartDate).toDate() : moment().toDate(),
    endDate: initialEndDate ? moment(initialEndDate).toDate() : undefined,
    key: 'selection',
  });
  const ref = useRef<HTMLDivElement>(null);
  const initialSetup = useRef(true);
  const { startDate, endDate } = state;
  const currentDates = useRef({ startDate, endDate });

  useEffect(() => {
    if (forceActive !== undefined) {
      setIsActive(forceActive);
    }
  }, [forceActive]);

  useEffect(() => {
    if (isActive) {
      ref.current?.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }, [isActive]);

  useEffect(() => {
    if (
      !initialSetup.current &&
      !(
        moment(currentDates.current.startDate).isSame(moment(startDate), 'day') &&
        moment(currentDates.current.endDate).isSame(moment(endDate), 'day')
      )
    ) {
      onChange(moment(startDate).format('YYYY-MM-DD'), moment(endDate).format('YYYY-MM-DD'));
      currentDates.current = { startDate, endDate };
    }
    initialSetup.current = false;
  }, [startDate, endDate, onChange]);

  const mStartDate = moment(startDate);
  const mEndDate = moment(endDate);

  return (
    <>
      <div className={styles.Wrapper}>
        {trigger ? (
          trigger
        ) : (
          <button
            className={`${styles.Button} ${isActive ? styles.active : ''}`}
            onClick={() => setIsActive(!isActive)}
          >
            {mStartDate.format('MMM Do, YYYY')} - {mEndDate.format('MMM Do, YYYY')}
            <Event />
          </button>
        )}

        <div
          className={`${styles.RangeWrapper} ${align ? styles[align] : ''} ${!isActive ? styles.hidden : ''}`}
          ref={ref}
        >
          <DateRange
            editableDateInputs={true}
            onChange={(item) => {
              if (isActive) {
                setState(item.selection);
              }
            }}
            moveRangeOnFirstSelection={false}
            rangeColors={['var(--main)']}
            ranges={[state]}
          />
        </div>
        {isActive && (
          <div
            className={styles.Overlay}
            onClick={() => {
              setIsActive(false);
              updateForceActive?.(false);
            }}
          />
        )}
      </div>
    </>
  );
};
